import axios from "axios";
import isEmpty from "is-empty";
import backurl from "../lib/config"
import {
    GET_ERRORS,
    FORM_ADD,
    FORM_UPDATE,
    FORMLAND_UPDATE
} from "./types";

export const addForm = (param, history) => dispatch => {

    var formData = new FormData();
    formData.append('pid', 0);
    formData.append('risk', 5);
    formData.append('lpSymbol', param.tokenSymbol + '-' + param.quoteTokenSymbol + ' LP');
    formData.append('alloc', param.alloc * 100);
    formData.append('isTokenOnly', false);
    formData.append('lpAddresses', param.lpAddresses);
    formData.append('tokenSymbol', param.tokenSymbol);
    formData.append('tokenAddresses', param.tokenAddresses);
    formData.append('quoteTokenSymbol', param.quoteTokenSymbol);
    formData.append('quoteTokenAdresses', param.quoteTokenAdresses);
    formData.append('depositFee', param.depositFee);
    formData.append('file', param.file);

    //console.log("formdata>>>>>>>",formData)   
    axios
        .post(backurl + "/api/form-add", formData)
        .then(res => {
            // console.log("yyyhyu>>",res)
            dispatch({
                type: FORM_ADD,
                payload: res,
            })
        }
        ).catch(err => {
            // console.log("yyyhyu>>",err)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        }
        );
};

export const updateLandForm = (param) => dispatch => {
console.log("contentdata jeje", param.file)
let files = param.files
let file = param.file;
    var formUpdateData = new FormData();
    if (!isEmpty(param.Identifier)) formUpdateData.append('Identifier', param.Identifier);
    if (!isEmpty(param.Description)) formUpdateData.append('Description', param.Description);
    if (!isEmpty(param.Title)) formUpdateData.append('Title', param.Title);
    if (!isEmpty(param.Content)) formUpdateData.append('Content', JSON.stringify(param.Content));


    for(let i=0; i< files.length; i++){
        formUpdateData.append('files' + files[i].index, param.files[i].ImageURI)
    }
    if(file != undefined){ formUpdateData.append('file', param.file) }
    console.log("formdatas_get", formUpdateData.get('Content'))
    axios.post( backurl + "/api/update/landing", formUpdateData)
    .then((res) => {
        dispatch({
            type: FORMLAND_UPDATE,
            payload: res,

        })

    }).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err?.response?.data
        })
        )


}
export const updateForm = (param) => dispatch => {

    console.log("parammmmmmmmmmmm", param);
    var formUpdateData = new FormData();
    if (!isEmpty(param._id)) formUpdateData.append('_id', param._id);
    if (!isEmpty(param.identifier)) formUpdateData.append('identifier', param.identifier);

    if (!isEmpty(param.Title)) formUpdateData.append('Title', param.Title);
    if (!isEmpty(param.Heading)) formUpdateData.append('Heading', param.Heading);
    if (!isEmpty(param.Description)) formUpdateData.append('Description', param.Description);
    if (!isEmpty(param.content)) formUpdateData.append('content', param.content);


    if (!isEmpty(param.file)) {
        formUpdateData.append('file', param.file);

    }

    axios
        .post(backurl + "/api/update/about", formUpdateData)
        .then(res =>

            dispatch({
                type: FORM_UPDATE,
                payload: res,

            })


        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
export const addFormdata = (param) => dispatch => {

    console.log("parammmmmcdcdmmmmmmm", param);
    var formUpdateData = {
        'Title': param.Title,
        'Heading': param.Heading,
        'Description': param.Description,
        'content': param.content
    };

    axios
        .post(backurl + "/api/add/about", formUpdateData)
        .then(res =>

            dispatch({
                type: FORM_UPDATE,
                payload: res,

            })


        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
export const updateArticalStatus = (param) => dispatch => {


    var formUpdateData = new FormData();
    if (!isEmpty(param._id)) formUpdateData.append('_id', param._id);
    if (!isEmpty(param.status)) formUpdateData.append('status', param.status);

    axios
        .post(backurl + "/api/status-articals", formUpdateData)
        .then(res =>

            dispatch({
                type: FORM_UPDATE,
                payload: res,

            })


        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};




export const addnewsDes = (param, history) => dispatch => {

    var stakeData = new FormData();
    stakeData.append('Title', param.Title);
    stakeData.append('Heading', param.Heading);
    stakeData.append('Description', param.Description);
    stakeData.append('NewsLink', param.NewsLink);
    stakeData.append('VimeoLink',param.VimeoLink );
    if (!isEmpty(param.file)) {
        stakeData.append('file', param.file);

    }
    axios
        .post(backurl + "/api/add/newsdes", stakeData)
        .then(res => {

            dispatch({
                type: FORM_ADD,
                payload: res,
            })
        }
        ).catch(err => {
            // console.log("yyyhyu>>",err)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        }
        );
};

export const updateNewsDes = (param) => dispatch => {


    var formUpdateData = new FormData();
    if (!isEmpty(param._id)) formUpdateData.append('_id', param._id);

    if (!isEmpty(param.Title)) formUpdateData.append('Title', param.Title);
    if (!isEmpty(param.Heading)) formUpdateData.append('Heading', param.Heading);
    if (!isEmpty(param.Description)) formUpdateData.append('Description', param.Description);
    if (!isEmpty(param.NewsLink)) formUpdateData.append('NewsLink', param.NewsLink);
    if (!isEmpty(param.VimeoLink))formUpdateData.append('VimeoLink',param.VimeoLink );
    if (!isEmpty(param.file)) {
        formUpdateData.append('file', param.file);

    }

    axios
        .post(backurl + "/api/update/newsdes", formUpdateData)
        .then(res =>

            dispatch({
                type: FORM_UPDATE,
                payload: res,

            })


        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};




export const addArticles = (param) => dispatch => {

    var formUpdateData = new FormData();

    console.log("param", param);
    if (!isEmpty(param.Title)) formUpdateData.append('Title',param.Title );
    if (!isEmpty(param.Heading)) formUpdateData.append('Heading',param.Heading );
    if (!isEmpty(param.Description))formUpdateData.append('Description',param.Description );
    if (!isEmpty(param.NewsLink))formUpdateData.append('NewsLink',param.NewsLink );
    if (!isEmpty(param.VimeoLink))formUpdateData.append('VimeoLink',param.VimeoLink );
    if (!isEmpty(param.Description1))formUpdateData.append('Description1',param.Description1 );
    if (!isEmpty(param.NewsLink1))formUpdateData.append('NewsLink1',param.NewsLink1 );
    if (!isEmpty(param.VimeoLink1))formUpdateData.append('VimeoLink1',param.VimeoLink1 );
    if (!isEmpty(param.Description2))formUpdateData.append('Description2',param.Description2 );
    if (!isEmpty(param.NewsLink2))formUpdateData.append('NewsLink2',param.NewsLink2 );
    if (!isEmpty(param.VimeoLink2))formUpdateData.append('VimeoLink2',param.VimeoLink2 );
    if (!isEmpty(param.Description3))formUpdateData.append('Description3',param.Description3 );
    if (!isEmpty(param.NewsLink3))formUpdateData.append('NewsLink3',param.NewsLink3 );
    if (!isEmpty(param.VimeoLink3))formUpdateData.append('VimeoLink3',param.VimeoLink3);
    if (!isEmpty(param.status))formUpdateData.append('status',param.status );
    if (!isEmpty(param.file)) {
        formUpdateData.append('file', param.file);

    }

    axios
        .post(backurl + "/api/add/articles", formUpdateData)
        .then(res =>

            dispatch({
                type: FORM_UPDATE,
                payload: res,

            })


        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const updateArticles = (param) => dispatch => {

    var formUpdateData = new FormData();
    if (!isEmpty(param._id)) formUpdateData.append('_id', param._id);

    if (!isEmpty(param.Title)) formUpdateData.append('Title',param.Title );
    if (!isEmpty(param.Heading)) formUpdateData.append('Heading',param.Heading );
    if (!isEmpty(param.Description))formUpdateData.append('Description',param.Description );
    if (!isEmpty(param.NewsLink))formUpdateData.append('NewsLink',param.NewsLink );
    if (!isEmpty(param.VimeoLink))formUpdateData.append('VimeoLink',param.VimeoLink );
    if (!isEmpty(param.Description1))formUpdateData.append('Description1',param.Description1 );
    if (!isEmpty(param.NewsLink1))formUpdateData.append('NewsLink1',param.NewsLink1 );
    if (!isEmpty(param.VimeoLink1))formUpdateData.append('VimeoLink1',param.VimeoLink1 );
    if (!isEmpty(param.Description2))formUpdateData.append('Description2',param.Description2 );
    if (!isEmpty(param.NewsLink2))formUpdateData.append('NewsLink2',param.NewsLink2 );
    if (!isEmpty(param.VimeoLink2))formUpdateData.append('VimeoLink2',param.VimeoLink2 );
    if (!isEmpty(param.Description3))formUpdateData.append('Description3',param.Description3 );
    if (!isEmpty(param.NewsLink3))formUpdateData.append('NewsLink3',param.NewsLink3 );
    if (!isEmpty(param.VimeoLink3))formUpdateData.append('VimeoLink3',param.VimeoLink3 );
    if (!isEmpty(param.status))formUpdateData.append('status',param.status );

    if (!isEmpty(param.file)) {
        formUpdateData.append('file', param.file);

    }

    axios
        .post(backurl + "/api/update/articles", formUpdateData)
        .then(res =>

            dispatch({
                type: FORM_UPDATE,
                payload: res,

            })


        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};



export const addAI1 = (param, history) => dispatch => {

    var stakeData = new FormData();

    if (!isEmpty(param.file)) {
        stakeData.append('file', param.file);

    }
    axios
        .post(backurl + "/api/add/aimage1", stakeData)
        .then(res => {

            dispatch({
                type: FORM_ADD,
                payload: res,
            })
        }
        ).catch(err => {
            // console.log("yyyhyu>>",err)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        }
        );
};

export const updateAI1 = (param) => dispatch => {


    var formUpdateData = new FormData();
    if (!isEmpty(param._id)) formUpdateData.append('_id', param._id);
    if (!isEmpty(param.file)) {
        formUpdateData.append('file', param.file);

    }

    axios
        .post(backurl + "/api/update/aimage1", formUpdateData)
        .then(res =>

            dispatch({
                type: FORM_UPDATE,
                payload: res,

            })


        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};


export const addAI2 = (param, history) => dispatch => {

    var stakeData = new FormData();

    if (!isEmpty(param.file)) {
        stakeData.append('file', param.file);

    }
    axios
        .post(backurl + "/api/add/aimage2", stakeData)
        .then(res => {

            dispatch({
                type: FORM_ADD,
                payload: res,
            })
        }
        ).catch(err => {
            // console.log("yyyhyu>>",err)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        }
        );
};

export const updateAI2 = (param) => dispatch => {


    var formUpdateData = new FormData();
    if (!isEmpty(param._id)) formUpdateData.append('_id', param._id);
    if (!isEmpty(param.file)) {
        formUpdateData.append('file', param.file);

    }

    axios
        .post(backurl + "/api/update/aimage2", formUpdateData)
        .then(res =>

            dispatch({
                type: FORM_UPDATE,
                payload: res,

            })


        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const addAI3 = (param, history) => dispatch => {

    var stakeData = new FormData();

    if (!isEmpty(param.file)) {
        stakeData.append('file', param.file);

    }
    axios
        .post(backurl + "/api/add/aimage3", stakeData)
        .then(res => {

            dispatch({
                type: FORM_ADD,
                payload: res,
            })
        }
        ).catch(err => {
            // console.log("yyyhyu>>",err)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        }
        );
};

export const updateAI3 = (param) => dispatch => {


    var formUpdateData = new FormData();
    if (!isEmpty(param._id)) formUpdateData.append('_id', param._id);
    if (!isEmpty(param.file)) {
        formUpdateData.append('file', param.file);

    }

    axios
        .post(backurl + "/api/update/aimage3", formUpdateData)
        .then(res =>

            dispatch({
                type: FORM_UPDATE,
                payload: res,

            })


        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const addAI4 = (param, history) => dispatch => {

    var stakeData = new FormData();

    if (!isEmpty(param.file)) {
        stakeData.append('file', param.file);

    }
    axios
        .post(backurl + "/api/add/aimage4", stakeData)
        .then(res => {

            dispatch({
                type: FORM_ADD,
                payload: res,
            })
        }
        ).catch(err => {
            // console.log("yyyhyu>>",err)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        }
        );
};

export const updateAI4 = (param) => dispatch => {


    var formUpdateData = new FormData();
    if (!isEmpty(param._id)) formUpdateData.append('_id', param._id);
    if (!isEmpty(param.file)) {
        formUpdateData.append('file', param.file);

    }

    axios
        .post(backurl + "/api/update/aimage4", formUpdateData)
        .then(res =>

            dispatch({
                type: FORM_UPDATE,
                payload: res,

            })


        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};